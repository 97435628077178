<template>
  <span class="VueTables__sort float-left mx-2"> <i :class="icon" class="fal"></i>&nbsp; </span>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'VtSortControl',
  props: ['props'],
  setup(props) {
    const icon = computed(() => {
      if (!props.props.sortStatus.sorted) return 'fal fa-arrow-up-arrow-down';
      return props.props.sortStatus.asc ? 'fa-arrow-down' : 'fa-arrow-up';
    });
    return {
      icon,
    };
  },
};
</script>
