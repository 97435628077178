import type { AxiosResponse } from 'axios';
import { http } from '@/services/http.service';

export default async function stripeInit() {
  const plans = async (): Promise<AxiosResponse> => {
    return await http.get('/api/v1/alldoq_plans/tokens');
  };
  return await plans()
    .then(async (response) => {
      window.localStorage.setItem('stripePublicKey', response.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
}
