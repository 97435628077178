<template>
  <div class="dataTables_wrapper">
    <div class="dataTables_paginate paging_simple_numbers mb-2">
      <paginate
        v-model="props.page"
        :page-count="props.totalPages"
        :click-handler="clickCallback"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :prev-text="'«'"
        :next-text="'»'"
        @input="(page) => props.setPage(page)"
        v-if="props.totalPages > 1"
      />
    </div>
    <span
      class="text-muted font-bolder"
      v-if="props.totalPages > 1"
      style="color: #b5b5c3 !important"
    >
      Showing {{ lower }}&nbsp;to&nbsp;{{ upper }} of total {{ props.records }} records
    </span>
    <span
      class="text-muted font-bolder"
      v-if="props.totalPages === 1"
      style="color: #b5b5c3 !important"
    >
      Total {{ props.records }} {{ entiresWord }}
    </span>
  </div>
</template>
<script>
//@ts-nocheck
import { computed, reactive } from 'vue';
import Paginate from 'vuejs-paginate-next';
export default {
  name: 'VtPagination',
  components: {
    Paginate,
  },
  setup(props) {
    const entiresWord = computed(() => {
      if (props.props.records === 1) {
        return 'record';
      } else {
        return 'records';
      }
    });
    const lower = computed(() => {
      if (props.props.page == 1) {
        return props.props.page;
      } else {
        return (props.props.page - 1) * (props.props.perPage + 1);
      }
    });
    const upper = computed(() => {
      return Math.min(props.props.records, props.props.page * props.props.perPage);
    });
    const clickCallback = (pag) => {
      props.props.setPage(pag);
    };
    const bootstrapPaginationClasses = reactive({
      ul: 'pagination',
      li: 'paginate_button page-item',
      liActive: 'active',
      liDisable: 'disabled',
      button: 'page-link',
      buttonActive: 'active',
      buttonDisable: 'disabled',
    });
    return {
      bootstrapPaginationClasses,
      lower,
      upper,
      entiresWord,
      clickCallback,
    };
  },
  props: ['props'],
};
</script>
