<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <i class="fal fa-search"></i>
      </span>
    </div>
    <input
      @keyup="(e) => props.search(props.debounce)(e)"
      :class="props.theme.input"
      class="shadow-none filter rounded-0"
      :name="props.name"
      type="text"
      autocomplete="off"
    />
  </div>
</template>

<script>
//@ts-nocheck
import { defineExpose } from 'vue';
export default {
  props: ['props'],
  setup(props) {
    defineExpose(['props']);
  },
};
</script>
