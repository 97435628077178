import { Component, createApp } from 'vue';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import VueSelect from 'vue-select';
import { ServerTable, ClientTable, EventBus } from 'v-tables-3';
import SortControl from '../pages/panel/override/SortControl.vue';
import TextFilter from '../pages/panel/override/TextFilter.vue';
import TablePagination from '../pages/panel/override/TablePagination.vue';
import { globalProperties } from './globalProperties';
import stripeInit from '@/services/stripe.service';
import AuthService from '@/services/auth.service';
import { setHTTPHeader } from '@/services/http.service';
import { pinia } from '@/stores';
import 'bootstrap/scss/bootstrap.scss';
import 'vue-select/dist/vue-select.css';
const token = AuthService.getToken();
stripeInit();
if (token) {
  setHTTPHeader({ Authorization: token });
}

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent);

  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(ServerTable, {}, 'bootstrap4', {
    sortControl: SortControl,
    textFilter: TextFilter,
    pagination: TablePagination,
  });
  app.use(ClientTable, {}, 'bootstrap4', {
    sortControl: SortControl,
  });
  app.component('VSelect', VueSelect);
  app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };
  app.config.performance = true;
  window.vtEvent = EventBus;
  app.mount('#app');
};
